import React from 'react';

interface Props {}

export const LoadingSpinner: React.FC<Props> = () => {
  return (
    <div className="progress">
      <svg
        className="hpl2-LoadingSpinner"
        viewBox="0 0 40 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle className="hpl2-LoadingSpinner__back" cx="20" cy="20" r="18" />
        <circle className="hpl2-LoadingSpinner__active" cx="20" cy="20" r="18" />
      </svg>
    </div>
  );
};
