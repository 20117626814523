import { CubeTexture } from '@babylonjs/core/Materials/Textures/cubeTexture';
import { Scene } from '@babylonjs/core/scene';
import { gradToRadRatio } from './math';

export const setSandboxScene = (scene: Scene) => {
  const envTexture = CubeTexture.CreateFromPrefilteredData(
    'assets/studio-skybox.env',
    // 'assets/sandbox/studio.env',
    // 'assets/sandbox/environmentSpecular.env',
    scene,
  );
  // const envTexture = new CubeTexture('assets/sandbox/environmentSpecular.env', scene);
  // const envTexture = new CubeTexture('assets/studio-skybox.env', scene);
  envTexture.rotationY = 75 * gradToRadRatio;
  scene.environmentTexture = envTexture;

  // scene.createDefaultSkybox(
  //   scene.environmentTexture,
  //   true,
  //   (scene.activeCamera!.maxZ - scene.activeCamera!.minZ) / 2,
  //   0.3,
  //   false,
  // );
};
