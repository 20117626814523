import React from 'react';
import { parse } from 'query-string';
import { ShowError } from './components/ShowError/ShowError';
import { PreviewWidget } from './components/PreviewWidget/PreviewWidget';
import { getWindow } from './services/window.service';
import { fileList } from './services/fileList';
import { getColor } from './babylonjs-utils/get-color';

const wnd = getWindow();

const getFileExtension = (filename: string | string[] | null): string => {
  if (!filename) {
    return '';
  }
  if (Array.isArray(filename)) {
    return '';
  }
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
};

const App: React.FC = () => {
  const params = parse(wnd.location.search, { arrayFormat: 'comma' });
  const { file = '', scaling, rotation, pitch, yaw, distance, bg, vignette: bgVignette } = params;
  const ext = getFileExtension(file);

  if (!file || fileList.indexOf(file as string) < 0) {
    return <ShowError message="404. File not found" />;
  }
  if (ext.toLowerCase() !== 'glb') {
    return <ShowError message="Unsupported file format" />;
  }

  return (
    <PreviewWidget
      filename={getString(file)}
      scaling={getArray(scaling)}
      rotation={getArray(rotation)}
      pitch={getNumber(pitch)}
      yaw={getNumber(yaw)}
      distance={getNumber(distance, 1)}
      backgroundColor={getColor(getString(bg), true)}
      vignette={getBoolean(bgVignette)}
    />
  );
};

export default App;

const getArray = (value: string | string[] | null): number[] => {
  if (!value) {
    return [0, 0, 0];
  }
  let arr: string[];
  if (Array.isArray(value)) {
    arr = value;
  } else {
    arr = [value, value, value];
  }
  return arr.map(Number);
};

const getString = (value: string | string[] | null): string => {
  if (!value) {
    return '';
  }
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
};

const getBoolean = (value: string | string[] | null): boolean => {
  return getString(value).toLowerCase() === 'true';
};

const getNumber = (value: string | string[] | null, orValue: number = 0): number => {
  if (!value) {
    return orValue;
  }
  if (Array.isArray(value)) {
    return Number(value[0]);
  }
  return Number(value);
};
