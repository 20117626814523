import { Engine } from '@babylonjs/core/Engines/engine';
import { Color4 } from '@babylonjs/core/Maths/math.color';
import { Scene } from '@babylonjs/core/scene';
import { Animation } from '@babylonjs/core/Animations/animation';

export interface SceneOptions {
  clearColor: Color4;
}

type onDispose = () => void;
type CreateSceneCallback = [Scene, onDispose];

export const createScene = (
  canvas: HTMLCanvasElement,
  options: SceneOptions,
): CreateSceneCallback => {
  Engine.ShadersRepository = '/src/Shaders/';

  // This is really important to tell Babylon.js to use decomposeLerp and matrix interpolation
  Animation.AllowMatricesInterpolation = true;
  const engine = new Engine(
    canvas,
    true,
    {
      preserveDrawingBuffer: true,
      stencil: true,
      premultipliedAlpha: false,
    },
    true,
  );
  // engine.loadingUIBackgroundColor = '#2A2342';
  const scene = new Scene(engine);
  scene.clearColor = options.clearColor;

  const dispose = () => {
    scene.dispose();
    engine.dispose();
  };
  return [scene, dispose];
};
