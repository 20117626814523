import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { BoundingSphere } from '@babylonjs/core/Culling/boundingSphere';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { Scene } from '@babylonjs/core/scene';
import { Nullable } from '@babylonjs/core/types';
import { gradToRadRatio } from './math';
import { Animation } from '@babylonjs/core/Animations/animation';
import { EasingFunction, QuadraticEase } from '@babylonjs/core/Animations/easing';
import { FramingBehavior } from '@babylonjs/core/Behaviors/Cameras/framingBehavior';

type MoveCameratToTargetOptions = {
  distanceCoefficient?: number;
  verticalAdjustmentInGrad?: number;
  horizontalAdjustmentInGrad?: number;
  framePerSecond?: number;
  totalFrame?: number;
};

export const moveCamera = (
  mesh: Nullable<AbstractMesh>,
  camera?: ArcRotateCamera,
  scene?: Scene,
  options: MoveCameratToTargetOptions = {},
) => {
  if (!scene || !mesh || !camera) {
    return;
  }
  const {
    distanceCoefficient = 1,
    horizontalAdjustmentInGrad = 0,
    verticalAdjustmentInGrad = 0,
  } = options;

  const arcRotateCamera: ArcRotateCamera = camera;
  arcRotateCamera.setTarget(Vector3.Zero());
  arcRotateCamera.setPosition(Vector3.Zero());

  const engine = scene.getEngine();
  const bounds = mesh.getHierarchyBoundingVectors(true);
  const boundingSphere = new BoundingSphere(bounds.min, bounds.max);
  const center = boundingSphere.centerWorld;
  const ratio = engine.getAspectRatio(arcRotateCamera);
  const adjustedRaduis = ratio <= 1 ? boundingSphere.radius / ratio : boundingSphere.radius;

  const distance = (distanceCoefficient * adjustedRaduis) / Math.sin(arcRotateCamera.fov / 2);
  const y = Math.tan(verticalAdjustmentInGrad * gradToRadRatio) * distance;
  const x = Math.tan(horizontalAdjustmentInGrad * gradToRadRatio) * distance;
  const position = new Vector3(x, y, -distance);

  camera.position = position;
  camera.target = center;
};

const easingMode = new QuadraticEase();
easingMode.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

export const zoomWithAnimation = (
  scene?: Scene,
  camera?: ArcRotateCamera,
  radius: number = 0,
  duration: number = 500,
) => {
  if (!scene || !camera) {
    return;
  }
  const from = camera.radius;
  const to = radius || from;

  camera.getScene().stopAllAnimations();
  const transition = Animation.CreateAnimation(
    'radius',
    Animation.ANIMATIONTYPE_FLOAT,
    60,
    FramingBehavior.EasingFunction,
  );
  Animation.TransitionTo('radius', to, camera, camera.getScene(), 60, transition, duration);
};
