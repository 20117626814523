import '@babylonjs/loaders/glTF';
import '@babylonjs/core/Loading/loadingScreen';
import '@babylonjs/core/Meshes/meshBuilder';
import { GLTFFileLoader } from '@babylonjs/loaders/glTF/glTFFileLoader';
import { SceneLoader } from '@babylonjs/core/Loading/sceneLoader';
import { Scene } from '@babylonjs/core/scene';
import { ISceneLoaderProgressEvent } from '@babylonjs/core/Loading/sceneLoader';

GLTFFileLoader.HomogeneousCoordinates = true;
GLTFFileLoader.IncrementalLoading = false;

export type OnProgressCallback = (value?: number) => void;

export const loadRootModel = async (
  url: string,
  scene: Scene,
  onProgress: OnProgressCallback = () => {},
) => {
  const result = await SceneLoader.ImportMeshAsync(
    null,
    '',
    url,
    scene,
    (e: ISceneLoaderProgressEvent) => {
      const { loaded = 1, total = 1 } = e;
      onProgress(loaded / total);
    },
  );
  onProgress();
  return result;
};
