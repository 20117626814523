import React, { CSSProperties } from 'react';

interface Props {
  message?: string;
}

export const ShowError: React.FC<Props> = ({ message = 'Oops. Uknown error' }) => {
  return <div style={style}>{message}</div>;
};

const style: CSSProperties = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0.3,
  fontSize: '3rem',
};
