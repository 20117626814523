import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Scene } from '@babylonjs/core/scene';
import '@babylonjs/core/Helpers/sceneHelpers';
import { ArcRotateCameraKeyboardMoveInput } from '@babylonjs/core/Cameras/Inputs/arcRotateCameraKeyboardMoveInput';

export const createArcRotateCamera = (name: string, scene: Scene, attachControl: boolean) => {
  const alpha = 0;
  const beta = 0;
  const radius = 1;
  const camera = new ArcRotateCamera(name, alpha, beta, radius, Vector3.Zero(), scene);

  camera.panningSensibility = 15; // TODO re-calculate it after mesh loading
  // camera.pinchDeltaPercentage = .1;
  camera.wheelDeltaPercentage = 0.1;
  // camera.pinchPrecision = 0.5;
  // camera.minZ = 10;
  camera.minZ = 0.1;
  camera.maxZ = 2500; // TODO recalulation required
  camera.collisionRadius = new Vector3(0.5, 0.5, 0.5);
  if (attachControl) {
    const noPreventDefault = false;
    const useCtrlForPanning = true;
    const canvas = scene.getEngine().getInputElement();
    camera.attachControl(canvas!, noPreventDefault, useCtrlForPanning);
  }
  return camera;
};

export const createDefaultCamera = (scene: Scene): ArcRotateCamera => {
  scene.createDefaultCamera(true, true, false);
  const camera = scene.activeCamera as ArcRotateCamera;

  // // allow mouse down event bubbling
  scene.preventDefaultOnPointerDown = false;
  camera.attachControl(scene.getEngine().getInputElement()!, true);

  // Setup camera touch and mouse input behavior
  camera.pinchPrecision = 200 / camera.radius;
  camera.lowerRadiusLimit = 0.025;
  // camera.upperBetaLimit = Math.PI / 2;
  camera.upperRadiusLimit = 800 * camera.radius;
  camera.wheelDeltaPercentage = 0.01;
  camera.pinchDeltaPercentage = 0.01;
  camera.speed = 10;
  camera.panningSensibility = 0;
  camera.radius = 400;
  camera.maxZ = 2500;
  camera.minZ = 10;
  // camera.inputs.remove(camera.inputs.attached.keyboard);

  // Adjust camera movement behavior
  camera.useFramingBehavior = true;
  if (camera.framingBehavior) {
    camera.framingBehavior.framingTime = 0;
    camera.framingBehavior.elevationReturnTime = -1;
  }
  return camera;
};
