import { Color3, Color4 } from '@babylonjs/core/Maths/math.color';

const reHex = /^([0-9a-f]{6}|[0-9a-f]{8})$/i;

export const getColor = (color: string, toLinearSpace: boolean = false): Color4 | undefined => {
  if (!color) {
    return;
  }
  if (!reHex.test(color)) {
    console.error(`Color should be 6 or 8 characters length, it is "${color}"`);
    return;
  }
  const hex = `#${color}`;
  let result: Color4;
  if (hex.length === 7) {
    result = Color4.FromColor3(Color3.FromHexString(hex));
  } else {
    result = Color4.FromHexString(hex);
  }
  if (toLinearSpace) {
    return result.toLinearSpace();
  }
  return result;
};
