import React, { useCallback, useEffect, useState } from 'react';
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { Scene } from '@babylonjs/core/scene';
import { Scene3D } from '../Scene3D/Scene3D';
import { createDefaultCamera } from '../../babylonjs-utils/create-arc-rotate-camera';
import { loadRootModel } from '../../babylonjs-utils/load-root-model';
import { moveCamera, zoomWithAnimation } from '../../babylonjs-utils/move-camera-with-animation';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
// import { setConfiguratorEnvironment } from '../../babylonjs-utils/set-configurator-scene';
import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator';
import { getWindow } from '../../services/window.service';
import { setSandboxScene } from '../../babylonjs-utils/set-sandbox-scene';
import { Color4 } from '@babylonjs/core/Maths/math.color';
import { setPrimaryAndSecondaryColors } from '../../babylonjs-utils/set-primary-secondary-colors';
import { DefaultRenderingPipeline } from '@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/defaultRenderingPipeline';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { Controls } from '../Controls/Controls';
import { ArcRotateCameraKeyboardMoveInput } from '@babylonjs/core/Cameras/Inputs/arcRotateCameraKeyboardMoveInput';

const wnd = getWindow();
const { HOFFMANN } = wnd;
const { BLOB_STORAGE_URL } = HOFFMANN;

const ROOT_NODE_ID = '__root__';
const GRAD_TO_RAD = Math.PI / 180;

const gradToRad = (n: number) => n * GRAD_TO_RAD;

interface Props {
  filename: string;
  scaling: number[];
  rotation: number[];
  pitch: number;
  yaw: number;
  distance: number;
  backgroundColor?: Color4;
  vignette?: boolean;
}

export const PreviewWidget: React.FC<Props> = ({
  filename,
  scaling,
  rotation,
  pitch,
  yaw,
  distance,
  backgroundColor,
  vignette,
}) => {
  const [scene, setScene] = useState<Scene>();
  const [camera, setCamera] = useState<ArcRotateCamera>();
  const [loading, setLoading] = useState<boolean>(true);
  const [initialCameraRadius, setInitialCameraRadius] = useState<number>(0);

  const handleSceneCreated = useCallback(
    (createdScene: Scene) => {
      setScene(createdScene);

      createdScene.skipFrustumClipping = true;
      const newCamera = createDefaultCamera(createdScene);
      newCamera.useAutoRotationBehavior = true;
      setCamera(newCamera);
      // setConfiguratorEnvironment(createdScene);
      setSandboxScene(createdScene);

      (wnd as any).scene = createdScene;
      (wnd as any).camera = newCamera;

      const pipeline = new DefaultRenderingPipeline(
        'defaultPipeline', // The name of the pipeline
        true, // Do you want the pipeline to use HDR texture?
        createdScene, // The scene instance
        [newCamera], // The list of cameras to be attached to
      );
      pipeline.samples = 4;
      pipeline.sharpenEnabled = true;
      if (vignette) {
        pipeline.imageProcessingEnabled = true;
        pipeline.imageProcessing.vignetteEnabled = true;
        pipeline.imageProcessing.vignetteWeight = 5;
      }
    },
    [vignette],
  );

  useEffect(() => {
    if (!filename || !scene || !camera) {
      return;
    }

    const load = async () => {
      scene.getEngine().clearInternalTexturesCache();
      await loadRootModel(`${BLOB_STORAGE_URL}/${filename}`, scene);
      setPrimaryAndSecondaryColors(scene);
      setLoading(false);

      scene.animationsEnabled = true;

      const currentRoot = scene.getMeshByID(ROOT_NODE_ID);
      if (!currentRoot) {
        return;
      }
      (camera.inputs.attached.keyboard as ArcRotateCameraKeyboardMoveInput).zoomingSensibility = 3;

      currentRoot!.rotation = Vector3.FromArray(rotation.map(gradToRad));
      const directionalLight = scene.getLightByID('dir01');
      if (directionalLight) {
        const shadowGenerator = directionalLight.getShadowGenerator() as ShadowGenerator;
        shadowGenerator.addShadowCaster(currentRoot, true);
        shadowGenerator.recreateShadowMap();
      }
      moveCamera(currentRoot, camera, scene, {
        distanceCoefficient: distance,
        verticalAdjustmentInGrad: pitch,
        horizontalAdjustmentInGrad: yaw,
      });
      setInitialCameraRadius(camera.radius);
    };
    load();
  }, [filename, scaling, rotation, pitch, yaw, distance, camera, scene]);

  const handleZoomIn = useCallback(() => {
    if (!camera) {
      return;
    }
    zoomWithAnimation(scene, camera, camera.radius - camera.radius / 4);
  }, [initialCameraRadius, camera, scene]);

  const handleZoomOut = useCallback(() => {
    if (!camera) {
      return;
    }
    zoomWithAnimation(scene, camera, camera.radius + camera.radius / 3);
  }, [initialCameraRadius, camera, scene]);

  const handleReset = useCallback(() => {
    if (!camera) {
      return;
    }
    zoomWithAnimation(scene, camera, initialCameraRadius);
  }, [initialCameraRadius, camera, scene]);

  return (
    <>
      {loading && <LoadingSpinner />}
      <Scene3D
        clearColor={backgroundColor}
        onSceneCreated={handleSceneCreated}
        activeCamera={camera}
      />
      {!loading && (
        <Controls onReset={handleReset} onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
      )}
    </>
  );
};
