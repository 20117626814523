import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Camera } from '@babylonjs/core/Cameras/camera';
import { Scene } from '@babylonjs/core/scene';
import { getWindow } from '../../services/window.service';
import { createScene, SceneOptions } from '../../babylonjs-utils/create-scene';
import { Color4 } from '@babylonjs/core/Maths/math.color';

const wnd = getWindow();
const DEFAULT_CLEAR_COLOR = new Color4(0.9, 0.9, 0.9, 1);

interface Props {
  clearColor?: Color4;
  onSceneCreated?: (scene: Scene) => void;
  activeCamera?: Camera;
}

export const Scene3D: React.FC<Props> = ({
  activeCamera,
  clearColor = DEFAULT_CLEAR_COLOR,
  onSceneCreated,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [initialSceneOptions] = useState<SceneOptions>({ clearColor });
  const [scene, setScene] = useState<Scene>();
  const [renderStarted, setRenderStarted] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (scene) {
        scene.getEngine().resize();
      }
    };
    wnd.addEventListener('resize', handleResize);

    return () => {
      wnd.removeEventListener('resize', handleResize);
    };
  }, [scene]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const [newScene, dispose] = createScene(canvas, initialSceneOptions);
    setScene(newScene);
    return dispose;
  }, [canvasRef, initialSceneOptions]);

  useEffect(() => {
    if (scene && typeof onSceneCreated === 'function') {
      scene.whenReadyAsync().then(() => {
        onSceneCreated(scene);
      });
    }
  }, [scene, onSceneCreated]);

  useEffect(() => {
    if (!scene) {
      return;
    }
    if (activeCamera) {
      if (!renderStarted) {
        scene.getEngine().runRenderLoop(() => scene.render());
        setRenderStarted(true);
      }
      scene.setActiveCameraByID(activeCamera.id);
    } else {
      scene.getEngine().stopRenderLoop();
      setRenderStarted(false);
    }
  }, [scene, activeCamera, renderStarted]);

  return <canvas style={canvasStyle} ref={canvasRef}></canvas>;
};

const canvasStyle: CSSProperties = {
  bottom: 0,
  fontSize: 0,
  height: '100%',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  touchAction: 'none',
};
