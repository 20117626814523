interface HoffmannVariables {
  BLOB_STORAGE_URL: string;
}

interface HoffmannGlobals {
  HOFFMANN: HoffmannVariables;
}

export const getWindow = (): Window & HoffmannGlobals => {
  return (window as any) as Window & HoffmannGlobals;
};
