import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Scene } from '@babylonjs/core/scene';

const PRIMARY_MATERIAL = 'config_ral_primary';
const SECONDARY_MATERIAL = 'config_ral_secondary';
const RAL_7035 = Color3.FromHexString('#C5C7C4');
const RAL_7016 = Color3.FromHexString('#383E42');

export const setPrimaryAndSecondaryColors = (scene: Scene) => {
  scene.materials
    .filter((m) => m.name === PRIMARY_MATERIAL)
    .filter((m) => m instanceof PBRMaterial)
    .forEach((m) => {
      (m as PBRMaterial).albedoColor = RAL_7035.toLinearSpace();
    });
  scene.materials
    .filter((m) => m.name === SECONDARY_MATERIAL)
    .filter((m) => m instanceof PBRMaterial)
    .forEach((m) => {
      (m as PBRMaterial).albedoColor = RAL_7016.toLinearSpace();
    });
};
