export const fileList = [
  '920002_1000.glb',
  '920002_1500.glb',
  '920002_2000.glb',
  '920004_1000.glb',
  '920004_1500.glb',
  '920004_2000.glb',
  '920006_1000.glb',
  '920006_1500.glb',
  '920006_2000.glb',
  '920012_1000.glb',
  '920012_1500.glb',
  '920012_2000.glb',
  '920014_1000.glb',
  '920014_1500.glb',
  '920014_2000.glb',
  '920016_1000.glb',
  '920016_1500.glb',
  '920016_2000.glb',
  '920022_1000.glb',
  '920022_1500.glb',
  '920022_2000.glb',
  '920024_1000.glb',
  '920024_1500.glb',
  '920024_2000.glb',
  '920026_1000.glb',
  '920026_1500.glb',
  '920026_2000.glb',
  '927300_300_2.glb',
  '927300_300_3.glb',
  '927300_400_3.glb',
  '927300_400_4.glb',
  '927335_500.glb',
  '927341_1.glb',
  '927412_300.glb',
  '927412_400.glb',
  '927430_400.glb',
  '927430_500.glb',
  '927500_100.glb',
  '927500_125.glb',
  '927500_150.glb',
  '927500_200.glb',
  '927500_250.glb',
  '927500_300.glb',
  '927500_50.glb',
  '927500_75.glb',
  '927505_100.glb',
  '927505_125.glb',
  '927505_150.glb',
  '927505_200.glb',
  '927505_250.glb',
  '927505_300.glb',
  '927505_50.glb',
  '927505_75.glb',
  '927600_200_1.glb',
  '927600_300_2.glb',
  '927600_300_3.glb',
  '927600_400_4.glb',
  '927600_500_5.glb',
  '927620_500_5.glb',
  '927620_600_4.glb',
  '927620_700_5.glb',
  '927720_200.glb',
  '927720_300.glb',
  '927720_400.glb',
  '927720_500.glb',
  '927800_100.glb',
  '927800_125.glb',
  '927800_150.glb',
  '927800_200.glb',
  '927800_250.glb',
  '927800_300.glb',
  '927800_50.glb',
  '927800_75.glb',
  '927805_100.glb',
  '927805_125.glb',
  '927805_150.glb',
  '927805_200.glb',
  '927805_250.glb',
  '927805_300.glb',
  '927805_50.glb',
  '927805_75.glb',
  '928010_1362.glb',
  '928010_200.glb',
  '928010_481.glb',
  '928010_962.glb',
  '928050_1000.glb',
  '928050_1500.glb',
  '928050_2000.glb',
  '928055_1000.glb',
  '928055_1500.glb',
  '928055_2000.glb',
  '928056_1000.glb',
  '928056_1500.glb',
  '928056_2000.glb',
  '928070_1000.glb',
  '928070_1500.glb',
  '928070_2000.glb',
  '928070_500.glb',
  '928071_1000.glb',
  '928071_1500.glb',
  '928071_2000.glb',
  '928090_1000.glb',
  '928090_1500.glb',
  '928090_2000.glb',
  '928091_1000.glb',
  '928091_1500.glb',
  '928091_2000.glb',
  '928101_481.glb',
  '928101_962.glb',
  '928111_1000.glb',
  '928111_1500.glb',
  '928111_2000.glb',
  '928130_1000.glb',
  '928130_1500.glb',
  '928130_2000.glb',
  '928131_1000.glb',
  '928131_1500.glb',
  '928131_2000.glb',
  '928140_1000.glb',
  '928140_1500.glb',
  '928140_2000.glb',
  '928145_1000.glb',
  '928145_1500.glb',
  '928145_2000.glb',
  '928170_1.glb',
  '928170_2.glb',
  '928172_1.glb',
  '928175_1.glb',
  '928178_1.glb',
  '928201_1000.glb',
  '928201_1500.glb',
  '928201_2000.glb',
  '928201_300.glb',
  '928201_500.glb',
  '928205_1000.glb',
  '928205_1500.glb',
  '928205_2000.glb',
  '928211_1000.glb',
  '928211_1500.glb',
  '928211_2000.glb',
  '928220_115.glb',
  '928220_176.glb',
  '928220_250.glb',
  '928220_250_2.glb',
  '928230_1000_AA1.glb',
  '928230_1000_AB1.glb',
  '928230_1500_AA1.glb',
  '928230_1500_AB1.glb',
  '928230_2000_AA1.glb',
  '928230_2000_AB1.glb',
  '928235_1000_6.glb',
  '928235_1000_9.glb',
  '928235_1500_14.glb',
  '928235_1500_9.glb',
  '928235_2000_13.glb',
  '928235_2000_19.glb',
  '928250_1000.glb',
  '928250_1500.glb',
  '928250_2000.glb',
  '928270_1.glb',
  '928270_2.glb',
  '928275_2.glb',
  '928280_1.glb',
  '928441_1000.glb',
  '928441_1500.glb',
  '928441_2000.glb',
  '928442_1000.glb',
  '928442_1500.glb',
  '928442_2000.glb',
  '928443_1000.glb',
  '928443_1500.glb',
  '928443_2000.glb',
  '928444_1000.glb',
  '928444_1500.glb',
  '928444_2000.glb',
  '928460_CH250-ONOFF.glb',
  '928460_CH250V-2.glb',
  '928460_CUT-OUT.glb',
  '928460_DE250-ONOFF.glb',
  '928460_DE250V-2.glb',
  '928460_DK250-ONOFF.glb',
  '928460_DK250V-2.glb',
  '928460_FR250-ONOF.glb',
  '928460_FR250-ONOFF.glb',
  '928460_FR250V-2.glb',
  '928460_ON-OFF.glb',
  '928460_OUT-ONOFF.glb',
  '928600_1000.glb',
  '928600_1500.glb',
  '928600_2000.glb',
  '928602_1000.glb',
  '928602_1500.glb',
  '928602_2000.glb',
  '928608_1000.glb',
  '928608_1500.glb',
  '928608_2000.glb',
  '928620_15.glb',
  '928630_780.glb',
  '928650_14.glb',
  '928655_31.glb',
  '928655_47.glb',
  '928660.glb',
  '928660_8.glb',
  '928665_1.glb',
  '928753_M.glb',
];
