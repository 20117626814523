import React from 'react';
import { ReactComponent as Reset } from './reset.svg';
import { ReactComponent as Add } from './add.svg';
import { ReactComponent as Remove } from './remove.svg';

interface Props {
  onReset: () => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
}

export const Controls: React.FC<Props> = ({ onReset, onZoomIn, onZoomOut }) => {
  return (
    <div className="controls">
      <button className="reset" onClick={onReset}>
        <Reset />
      </button>
      <button onClick={onZoomIn}>
        <Add />
      </button>
      <button onClick={onZoomOut}>
        <Remove />
      </button>
    </div>
  );
};
